// export const paymentDetails = {
//     gpay:"ycfltmm72k63@idbi",
//     paytm:"ycfltmm72k63@idbi",
//     phonepe:"ycfltmm72k63@idbi",
//     upi:"ycfltmm72k63@idbi"
// }
export const paymentDetails = {
    gpay:"7300236408@okbizaxis",
    paytm:"7300236408@okbizaxis",
    phonepe:"7300236408@okbizaxis",
    upi:"7300236408@okbizaxis"
}
